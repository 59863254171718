import { useEffect, useState } from 'react'
import Lottie from 'react-lottie-player'
import useFeed from '../../lib/useFeed'
import useGroup from '../../lib/useGroup'
import useUser from '../../lib/useUser'
import HomePageEmpty from '../../public/lottie/HomePageEmpty.json'
import Container from '../common/Container'
import EmptyView from '../common/EmptyView'
import PaginatedWithButton from '../common/pagination/PaginatedWithButton'
import { useCacheKey } from '../context/CacheKey'
import CreateActivityButton from './CreateActivityButton'
import EventFilters from './Feed/EventFilters'
import FeedOverlay from './Feed/FeedOverlay'
import GroupFilters from './Feed/GroupFilters'
import Post from './Post'
import Event from './event/Event'

function Feed({
  filters,
  itemType,
  showButton,
  postInType,
  postInId,
  postInSlug,
  emptyAccessory = null,
  detailedActivityButton = false,
  modalVisible = false,
  EmptyOverrideComponent = null,
}) {
  if (itemType != null) {
    filters.item_type = itemType
  }

  const descriptor = itemType == null ? 'Activity' : itemType
  const descriptorPlural = itemType == null ? descriptor : `${itemType}s`
  const groupSlug = postInType === 'Group' ? postInSlug : null
  const { group } = useGroup(groupSlug)
  const showGroupFilters = itemType !== 'Event' && postInType === 'Group'
  const showEventFilters = itemType === 'Event'
  const [selectedFilters, setSelectedFilters] = useState(
    itemType === 'Event' ? { upcoming: true } : {}
  )
  const feedFilters = {
    ...filters,
    ...selectedFilters,
  }

  const {
    items,
    cacheKey,
    isLoadingInitialData,
    isLoadingMore,
    isReachingEnd,
    loadMore,
  } = useFeed(feedFilters)
  const { user } = useUser()

  const cacheKeyContext = useCacheKey()

  // Set cacheKey for feed in context,
  // no need to pass down to update/delete components
  useEffect(() => {
    cacheKeyContext.setCacheKey(cacheKey)
  }, [cacheKey])

  if (!items) {
    return (
      <Container className="mx-auto px-0">
        <div className="flex flex-col max-w-700 mx-auto">
          {showButton && user && (
            <CreateActivityButton
              postInType={postInType}
              postInId={postInId}
              postInSlug={postInSlug}
              itemType={itemType}
              detailedActivityButton={detailedActivityButton}
              modalVisible={modalVisible}
            />
          )}
          <FeedOverlay hideMessage />
        </div>
      </Container>
    )
  }

  const emptyInstructions = [
    {
      title: 'Check our blog for advice and tips on creating content.',
      icon: 'your-lab',
      link: 'https://www.communitylab.app/blog',
      linkLabel: 'Read our blog',
    },
    {
      title: 'Find topics, groups and people on the discover tab.',
      icon: 'discover',
      link: '/discover',
      linkLabel: 'Go to Discover',
    },
  ]

  const emptyView = EmptyOverrideComponent ? (
    <EmptyOverrideComponent />
  ) : (
    <EmptyView
      title={`No ${descriptorPlural?.toLowerCase()} to show`}
      instructions={emptyInstructions}
      accessory={
        emptyAccessory || (
          <Lottie
            loop
            animationData={HomePageEmpty}
            play
            style={{ width: 350, height: 350 }}
          />
        )
      }
    />
  )

  return (
    <Container className="mx-auto px-0">
      <div className="flex flex-col max-w-700 mx-auto">
        {showGroupFilters && (
          <GroupFilters
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            group={group}
          />
        )}
        {showEventFilters && (
          <EventFilters
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
        )}
        {showButton && user && (
          <CreateActivityButton
            postInType={postInType}
            postInId={postInId}
            postInSlug={postInSlug}
            itemType={itemType}
            detailedActivityButton={detailedActivityButton}
            modalVisible={modalVisible}
          />
        )}
        <div className="flex flex-col items-center">
          <PaginatedWithButton
            isLoadingMore={isLoadingMore}
            isLoadingInitialData={isLoadingInitialData}
            isReachingEnd={isReachingEnd}
            loadMore={loadMore}
            key={Object.values(selectedFilters).join('')}
            EmptyComponent={emptyView}
          >
            {items.map((item) =>
              item.type === 'Event' || itemType === 'Event' ? (
                <Event
                  event={item}
                  key={`event-${item.id}`}
                  postInId={postInId}
                  archived={group?.archived}
                />
              ) : (
                <Post
                  post={item}
                  key={`post-${item.id}`}
                  postInId={postInId}
                  archived={group?.archived}
                />
              )
            )}
          </PaginatedWithButton>
        </div>
      </div>
    </Container>
  )
}

export default Feed
