import usePaginated from './api/usePaginated'

export default function useFeed(filters = {}) {
  const { items, ...attributes } = usePaginated(
    () => '/api/feed',
    (response) => response?.items,
    filters
  )

  return {
    items,
    ...attributes,
  }
}
