import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import useUser from '../../lib/useUser'
import CLAvatar from '../common/CLAvatar'
import FloatingButton from '../common/FloatingButton'
import Icon from '../common/Icon'
import CreateActivityModal from '../common/modal/CreateActivityModal'
import CreateGroupModal from '../common/modal/CreateGroupModal'

const CreateActivityButton = ({
  postInType,
  postInId,
  postInSlug,
  itemType = 'Post',
  detailedActivityButton = false,
  modalVisible = false,
}) => {
  const router = useRouter()
  const { user } = useUser()
  const [activityModalVisible, setActivityModalVisible] = useState(modalVisible)
  const [createGroupModalVisible, setCreateGroupModalVisible] = useState(false)
  const [selectedItemType, setSelectedItemType] = useState(itemType)

  useEffect(() => {
    setActivityModalVisible(modalVisible)
  }, [modalVisible])

  const toggleActivityModal = () => {
    setActivityModalVisible((prevState) => !prevState)
  }

  const newEvent = () => {
    setSelectedItemType('Event')
    toggleActivityModal()
  }

  const newPost = () => {
    setSelectedItemType(itemType)
    toggleActivityModal()
  }

  return (
    <>
      <CreateActivityModal
        toggleModal={toggleActivityModal}
        isVisible={activityModalVisible}
        postInId={postInId}
        postInSlug={postInSlug}
        postInType={postInType}
        itemType={selectedItemType}
      />
      <CreateGroupModal
        isOpen={createGroupModalVisible}
        onRequestClose={() => setCreateGroupModalVisible(false)}
      />
      <div className="hidden select-none md:flex flex-col justify-center items-center focus:outline-none bg-white p-4 rounded-xl my-4">
        <div className="flex flex-row justify-start items-center w-full gap-4">
          <div className="min-w-12 w-12 h-12 flex-shrink-0">
            <CLAvatar
              key={`${user?.name}-cab-avatar`}
              avatar={user?.avatar}
              name={user?.name}
            />
          </div>
          <button
            className="p-3 bg-alt-bg rounded-lg w-full text-left"
            onClick={newPost}
          >
            <span className="text-base text-black opacity-20">
              {itemType === 'Post'
                ? 'Post something new...'
                : 'Create an event...'}
            </span>
          </button>
        </div>
        {detailedActivityButton && (
          <div className="flex flex-row justify-between items-center w-full mt-3">
            <SubActivityButton
              onClick={() => setCreateGroupModalVisible(true)}
              label="New group"
              icon="group"
              iconColor="topic-blue"
            />
            <SubActivityButton
              onClick={() => {
                router.push(`/chats/new?select=true`)
              }}
              label="New message"
              icon="responses"
              iconColor="topic-green"
            />
            <SubActivityButton
              onClick={newEvent}
              label="New event"
              icon="calendar"
              iconColor="topic-purple"
            />
          </div>
        )}
      </div>
      <div className="md:hidden">
        <FloatingButton
          icon={itemType === 'Post' ? 'post' : 'calendar'}
          onClick={toggleActivityModal}
          label="Create activity"
        />
      </div>
    </>
  )
}

const SubActivityButton = ({ onClick, label, icon, iconColor = 'primary' }) => (
  <button
    className="flex flex-row justify-center items-center w-52 py-3 bg-white hover:bg-alt-bg rounded-xl"
    onClick={onClick}
  >
    {icon && <Icon name={icon} color={iconColor} size={24} bold />}{' '}
    <span className="ml-3 text-base text-disabled font-bold">{label}</span>
  </button>
)

export default CreateActivityButton
