import Icon from '../../common/Icon'
import Loading from '../../common/Loading'
import Post from '../Post'

export default function FeedOverlay({ isPrivate, hideMessage = false }) {
  const dummyPost = {
    title: 'Private content',
    edited: true,
    content_delta: {
      ops: [
        {
          insert:
            "This group is private. You must be invited to join and become a member to view this content.\n\nIf you're reading this you either have an old browser (and should consider upgrading to something more modern) or have been snooping. Nice try but you can't view this without joining.\n\nIf you want to know more about Community Lab get in touch with us.\n",
        },
      ],
    },
    created_at: '2021-03-01T00:00:00.000Z',
    updated_at: '2021-03-01T00:00:00.000Z',
    plain_text:
      "This group is private. You must be invited to join and become a member to view this content.\n\n\n\nIf you're reading this you either have an old browser (and should consider upgrading to something more modern) or have been snooping. Nice try but you can't view this without joining.\n\n\n\nIf you want to know more about Community Lab get in touch with us.",
    user: {
      slug: 'tony-stark',
      name: 'Tony Stark',
    },
    reply_count: 0,
    replies: [],
    type: 'Post',
    upvote_count: 12,
    user_has_upvoted: true,
  }

  return (
    <div className="relative">
      <div className="absolute top-0 left-0 z-10 bottom-48 w-full h-full bg-gray-100 bg-opacity-50 backdrop-filter backdrop-blur-sm" />
      {!hideMessage && (
        <div className="z-99 bg-white flex flex-col items-center rounded-xl px-6 py-12 absolute top-40 left-1/2 transform -translate-x-1/2 -translate-y-1/2 shadow-xl border-2 border-gray-100">
          <Icon name="lock" color="primary" />
          <h3 className="font-bold text-center">
            {isPrivate ? 'Private content' : 'Members only'}
          </h3>
          <p className="text-center text-sm">
            Click 'Join group' to see feed content
          </p>
        </div>
      )}
      {hideMessage && (
        <div className="z-99 flex flex-col items-center rounded-xl absolute top-40 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <Loading />
        </div>
      )}
      <Post post={dummyPost} archived hideAvatar collapsable={false} />
      <Post post={dummyPost} archived hideAvatar collapsable={false} />
    </div>
  )
}
