import classnames from 'classnames'
import isPinnedApp from '../../lib/isPinnedApp'
import Icon from './Icon'

export default function FloatingButton({ onClick, icon = 'plus', label = 'Floating button' }) {
  const buttonStyle = classnames(
    'bg-topic-green hover:bg-primary p-0 w-14 h-14 flex-row justify-center z-10 right-4 items-center fixed rounded-full shadow-lg focus:outline-none',
    {
      'bottom-20': !isPinnedApp(),
      'bottom-24': isPinnedApp(),
    }
  )
  return (
    <button onClick={onClick} tabIndex="0" className={buttonStyle} aria-label={label}>
      <Icon name={icon} color="white" size={28} />
    </button>
  )
}
